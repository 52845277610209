/* eslint-disable import/order */
import './Style/WebStyle.css';

import { AppBar, CssBaseline, Divider, Drawer, Grid, Hidden, IconButton, List, Toolbar } from '@mui/material';

import Banner from './Banner/Banner';
import Contacto from './Contacto/Contacto';
import DraftsIcon from '@mui/icons-material/Drafts';
import Ejemplos from './Ejemplos';
import Elegirnos from './Elegirnos/Elegirnos';
import Inicio from './Inicio/Inicio';
import LanguageIcon from '@mui/icons-material/Language';
import Pasos from './Pasos/Pasos';
import PasosResponsive from './Pasos/PasosResponsive';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Requisitos from './Requisitos/Requisitos';
import RequisitosResponsive from './Requisitos/RequisitosResponsive';
import Wp from '../assets/images/whatsapp-logo.png';
import clsx from 'clsx';
import logoColor from '../assets/images/logoColor.png';

const Web = () => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <CssBaseline />
      <AppBar position="fixed" className="appBarContainer">
        <Toolbar component="div">
          <nav className="title">
            <ul className="headerFirstLine">
              <Hidden only={['xs', 'sm', 'md']}>
                <li className="headerFirstLineLI">
                  <span>
                    <LanguageIcon className="headerIcon" />
                    Prestamos 100% online, sin comisiones ni pagos por adelantado
                  </span>
                </li>
                <li className="headerFirstLineLI">
                  <span>
                    <DraftsIcon className="headerIcon" />
                    ayuda@easycreditcolombia.com
                  </span>
                </li>
                <li className="headerFirstLineLI">
                  <span>
                    <PhoneAndroidIcon className="headerIcon" />
                    317 365 87 87
                  </span>
                </li>
              </Hidden>
            </ul>
            <ul className="headerSecondLine">
              <li>
                <a href="index.html">
                  <img src={logoColor} className="logoeasy" />
                </a>
              </li>
              <Hidden only={['sm', 'md', 'xs']}>
                <li>
                  <a className="cambioa" href="#inicio">
                    Inicio
                  </a>
                </li>
                <li>
                  <a className="cambioa" href="#ejemplos">
                    Ejemplo de tu crédito
                  </a>
                </li>
                <li>
                  <a className="cambioa" href="#pasos">
                    ¿Cómo adquirirlo?
                  </a>
                </li>

                <li>
                  {' '}
                  <a className="cambioa" href="#porqueelegirnos">
                    ¿Por qué elegirnos?
                  </a>{' '}
                </li>
                <li>
                  <a className="cambioa" href="#contacto">
                    Contacto
                  </a>
                </li>
              </Hidden>
            </ul>
          </nav>
          <Hidden only={['xl', 'lg']}>
            <IconButton color="inherit" edge="end" onClick={handleDrawerOpen} className={clsx(open && 'hide')}>
              {/* <MenuIcon /> */}
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <main
      // className={clsx(classes.content, {
      //   [classes.contentShift]: open,
      // })}
      >
        <div className={'drawerHeaders'} />

        <>
          <div className="top" />
          <Grid container>
            <Inicio />
          </Grid>

          <Hidden only={['lg', 'xl', 'md']}>
            <Grid item xs={12}>
              <RequisitosResponsive />
            </Grid>
          </Hidden>

          <Hidden only={['xs', 'sm']}>
            <Grid container className="contenedorrequisitos">
              <Requisitos />
            </Grid>
          </Hidden>

          {/* <Grid container>
            <Ejemplos />
          </Grid> */}

          <Hidden only={['xs', 'sm', 'md']}>
            <Pasos />
          </Hidden>

          <Hidden only={['lg', 'xl']}>
            <PasosResponsive />
          </Hidden>

          <Hidden only={['xs', 'sm', 'md']}>
            <Elegirnos />
          </Hidden>

          {/* <Hidden only={['lg', 'xl']}>
            <ElegirnosResponsive />
          </Hidden> */}

          <Grid container>
            <Banner />
          </Grid>
          <Grid className="contenedor_InformacióndeContacto" id="contacto" item xs={12}>
            <Contacto />
          </Grid>

          <Grid item xs={12} className="footerbottom">
            <h6>Todos los derechos reservados.</h6>
          </Grid>
        </>
      </main>

      <Drawer
        className={'drawer'}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: 'drawerpaper',
        }}
      >
        <Grid item xs={12} className={'drawerHeaders'}>
          <div className={'drawerHeaders'}>
            {/* <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronLeftIcon className="iconoamarillo rotate" fontSize="large" />
              ) : (
                <ChevronLeftIcon className="iconoamarillo rotate" fontSize="large" />
              )}
            </IconButton> */}
          </div>
          <Divider />

          <List className="menuresponsive">
            <li>
              {' '}
              {/* <MenuIcon className="iconoamarillo" fontSize="large" /> */}
              <a className="cambioa" href="#inicio">
                Inicio{' '}
              </a>{' '}
            </li>
            <li>
              {' '}
              {/* <PhoneAndroidSharpIcon className="iconoamarillo" fontSize="large" /> */}
              <a className="cambioa" href="#pasos">
                {' '}
                ¿Como adquirirlo?
              </a>
            </li>
            <li>
              {/* <LanguageSharpIcon className="iconoamarillo" fontSize="large" /> */}
              <a className="cambioa" href="#quienessomos">
                ¿Quiénes Somos?
              </a>
            </li>
            <li>
              {' '}
              {/* <CreditCardSharpIcon className="iconoamarillo" fontSize="large" /> */}
              <a className="cambioa" href="#porqueelegirnos">
                ¿Por qué elegirnos?
              </a>{' '}
            </li>
            <li>
              {/* <AssignmentTurnedInIcon className="iconoamarillo" fontSize="large" /> */}
              <a className="cambioa" href="#contacto">
                Contacto
              </a>
            </li>
          </List>
        </Grid>
      </Drawer>
      <div className="wpIcon">
        <a href="https://api.whatsapp.com/send?phone=573173658787" target="_blank" rel="noreferrer">
          <img src={Wp} alt="WhatsApp" />
        </a>
      </div>
    </div>
  );
};

export default Web;

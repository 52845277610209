/* eslint-disable react/prop-types */
import MUIDataTable from 'mui-datatables';

// @mui
import { Card, Container, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import Scrollbar from '../scrollbar/Scrollbar';
import MuiDatatablesTextLabels from '../../helper/labelTablas';

export default function TableListPagination({ titulo, columnas, data, loading, onPageChange, totalCount, currentPage, onSearch }) {
  const [searchText, setSearchText] = useState('');

  const options = {
    filterType: 'checkbox',
    textLabels: MuiDatatablesTextLabels,
    serverSide: true,
    count: totalCount,
    page: currentPage - 1,
    rowsPerPage: 20,
    rowsPerPageOptions: [20],
    searchText,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        onPageChange(tableState.page + 1);
      }
      if (action === 'search') {
        setSearchText(tableState.searchText);
        onSearch(tableState.searchText);
      }
    },
    search: true,
    searchPlaceholder: 'Buscar crédito...'
  };
  return (
    <Container>
      {loading ? (
        <Grid>
          <Grid display="flex" justifyContent="center" alignItems="end">
            <Icon icon="line-md:loading-twotone-loop" color="#2a76d2" width="150" />
          </Grid>
        </Grid>
      ) : (
        <Card>
          <Scrollbar>
            <MUIDataTable 
              title={titulo} 
              data={data} 
              columns={columnas} 
              options={options} 
            />
          </Scrollbar>
        </Card>
      )}
    </Container>
  );
}
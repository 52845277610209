import Swal from 'sweetalert2';
import clienteAxios from '../../helper/axios';
import { cargarClientes, cargarObservacionCliente, cargarClienteAEditar } from './clientesSlice';
import { cargarObservacionCredito } from '../creditosActivos';

// export const startConsultarClientes = () => async (dispatch) => {
//   try {
//     const respuesta = await clienteAxios.get('/admin/clientes', {
//       headers: {
//         'Content-Type': 'application/json',
//         'x-token': localStorage.getItem('token') || '',
//       },
//     });
//     dispatch(cargarClientes(respuesta.data.clientes));
//   } catch (error) {
//     console.error(error);
//   }
// };

export const startConsultarClientes = (page = 1, searchTerm = '') => async (dispatch) => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      limit: '20'
    });
    
    if (searchTerm.trim()) {
      params.append('search', searchTerm.trim());
    }

    const respuesta = await clienteAxios.get(`/admin/clientes?${params}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || ''
      }
    });

    dispatch(cargarClientes(respuesta.data));
  } catch (error) {
    console.error(error);
  }
};

export const agregarComentarioAlUsuario = (comentario) => async (dispatch, getState) => {
  try {
    const respuesta = await clienteAxios.post(
      `admin/comentario-usuario/${getState()?.clientes?.clienteEditable?.id}`,
      {
        comentario,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );
    dispatch(cargarObservacionCliente(comentario));
    Swal.fire(respuesta.data.title, respuesta.data.mensaje, respuesta.data.success);
  } catch (error) {
    console.error(error);
  }
};

export const agregarComentarioCredito = (comentario) => async (dispatch, getState) => {
  try {
    const respuesta = await clienteAxios.post(
      `admin/comentario-credito/${getState().creditosActivos?.creditoEditable?.id}`,
      {
        comentario,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );
    dispatch(cargarObservacionCredito(comentario));

    Swal.fire(respuesta.data.title, respuesta.data.mensaje, respuesta.data.success);
  } catch (error) {
    console.error(error);
  }
};

export const actualizarCliente = (data, id) => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.put(
      `admin/cliente/${id}`,
      {
        data,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );
    dispatch(startConsultarClientes);
    dispatch(cargarClienteAEditar);
    Swal.fire(respuesta.data.title, respuesta.data.mensaje, respuesta.data.success);
  } catch (error) {
    console.error(error);
  }
};

import clienteAxios from '../../helper/axios';
import { cargarHistoricoClienteEditable, cargarHistoricos } from './historicosSlice';

// export const startConsultarCreditosHistorico = () => async (dispatch) => {
//   try {
//     const respuesta = await clienteAxios.get('/admin/historicos', {
//       headers: {
//         'Content-Type': 'application/json',
//         'x-token': localStorage.getItem('token') || '',
//       },
//     });
//     dispatch(cargarHistoricos(respuesta.data.historicos));
//   } catch (error) {
//     console.error(error);
//   }
// };


// eslint-disable-next-line consistent-return
export const startConsultarCreditosHistorico = (desde, hasta) => async (dispatch) => {
  try {
    const params = new URLSearchParams();
    if (desde) params.append('desde', desde);
    if (hasta) params.append('hasta', hasta);

    const respuesta = await clienteAxios.get(`/admin/historicos?${params}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });
    dispatch(cargarHistoricos(respuesta.data.historicos));
    return respuesta.data;
  } catch (error) {
    console.error(error);
  }
};

export const startHistoricoClienteEditable = (id) => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get(`admin/historicos/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });
    dispatch(cargarHistoricoClienteEditable(respuesta.data.historicos));
  } catch (error) {
    console.error(error);
  }
};

// export const startConsultarCreditosPaginados = (page = 1, searchTerm = '') => async (dispatch) => {
//   try {
//     const params = new URLSearchParams({
//       page: page.toString(),
//       limit: '20'
//     });
 
//     if (searchTerm.trim()) {
//       params.append('search', searchTerm.trim());
//     }
 
//     const respuesta = await clienteAxios.get(`/admin/creditos?${params}`, {
//       headers: {
//         'Content-Type': 'application/json',
//         'x-token': localStorage.getItem('token') || ''
//       }
//     });
 
//     dispatch(cargarHistoricos(respuesta.data));
//   } catch (error) {
//     console.error(error);
//   }
//  };


export const startConsultarCreditosPaginados = (page = 1, searchTerm = '') => async (dispatch) => {

  try {
    const params = new URLSearchParams({
      page: page.toString(),
      limit: '20'
    });
 
    if (searchTerm.trim()) {
      params.append('search', searchTerm.trim());
    }
 
    const respuesta = await clienteAxios.get(`/admin/allh?${params}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || ''
      }
    });

    dispatch(cargarHistoricos(respuesta.data));
  } catch (error) {
    console.error(error);
  }
 };
/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';

import TableListPagination from '../components/table-list/TableListPagination';
import { MAIN_PATH, PATH_NAME, TITLES } from '../constants';
import TableList from '../components/table-list';
import { cargarClienteAEditar, startConsultarClientes } from '../store';
import Iconify from '../components/iconify/Iconify';

export function GestorDatos() {
  const location = useLocation();
  const navigate = useNavigate();
  const [datos, setDatos] = useState({
    loading: true,
    data: [],
  });
  console.log("🚀 ~ GestorDatos ~ datos:", datos);
  const isGestorDatos = location?.pathname.includes(PATH_NAME.gestor_de_datos);
  const dispatch = useDispatch();
  const { clientes, totalCount, currentPage, loading } = useSelector(state => state.clientes);

  const handlePageChange = (newPage) => {
    dispatch(startConsultarClientes(newPage));
  };

  const handleSearch = useCallback((searchText) => {
    dispatch(startConsultarClientes(1, searchText));
  }, [dispatch]);


  useEffect(() => {
    dispatch(startConsultarClientes());
  }, [dispatch]);

  useEffect(() => {
    if (clientes.length > 0) {
      setDatos({
        loading: false,
        data: clientes.map((cliente) => ({
          nombre: `${cliente?.primerNombre} ${cliente?.segundoNombre || ''}`,
          apellido: `${cliente?.primerApellido} ${cliente?.segundoApellido || ''}`,
          email: cliente?.usuario?.email,
          cedula: cliente.cedula,
        })),
      });
    }
  }, [clientes]);


  const redireccionarEdicion = (cedula) => {
    const cliente = clientes.filter((cliente) => cliente.cedula === cedula)[0];
    dispatch(cargarClienteAEditar(cliente));
    navigate(`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_datos_cliente}`, { replace: true });
  };

  const columnas = [
    {
      label: 'Nombre',
      name: 'nombre',
      options: {
        filter: true,
      },
    },
    {
      label: 'Apellido',
      name: 'apellido',
      options: {
        filter: true,
      },
    },
    {
      label: 'Cedula',
      name: 'cedula',
      options: {
        filter: true,
      },
    },
    {
      label: 'Correo',
      name: 'email',
      options: {
        filter: true,
      },
    },
    {
      name: 'Gestionar',
      options: {
        sort: false,
        customBodyRender: (_, tableMeta) => (
          <>
            <Tooltip title="Gestionar" aria-label="Gestionar">
              <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[2])}>
                <Iconify icon="material-symbols:edit-square-outline" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title> {isGestorDatos ? TITLES.gestor_de_datos : TITLES.default} </title>
      </Helmet>

      {/* <TableList titulo="Gestión de Datos" columnas={columnas} loading={datos.loading} data={datos.data} /> */}
    
      <TableListPagination 
      titulo="Lista de Clientes"
      columnas={columnas}
      data={datos.data}
      loading={loading}
      onPageChange={handlePageChange}
      totalCount={totalCount}
      currentPage={currentPage}
      onSearch={handleSearch}
    />
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { PATH_NAME } from '../../constants';
import Iconify from '../../components/iconify';
import { Tile } from '../components';
import {
  startConsultarClientes,
  startConsultarCreditosActivos,
  startcontadorCreditosActivos,
  startcontadorBienestar,
  startInformesDone,
} from '../../store';

export const TilePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startcontadorCreditosActivos());
    dispatch(startcontadorBienestar());
    dispatch(startConsultarCreditosActivos(1));
    dispatch(startConsultarClientes());
    dispatch(startInformesDone());
  }, []);

  const {
    solicitudesNuevos,
    preAprobadosAntiguosSin,
    preAprobadosNuevos,
    paraAprobarNuevos,
    paraAprobarAntiguos,
    porDesembolsarNuevos,
    porDesembolsarAntiguos,

    renovacionNuevos,
    solicitudesAntiguos,
    renovacionAntiguos,
    preAprobadosAntiguosCon,
    preAprobadosNuevosConDocumentos,
  } = useSelector((state) => state.contadorCreditos.creditos);

  const { informes } = useSelector((state) => state.contadorInfor);

  const informesAntiguos = informes?.filter((informe) => informe?.cuentaUsuario?.antiguo === true);
  const informesNuevos = informes?.filter((informe) => informe?.cuentaUsuario?.antiguo !== true);

  const { loading } = useSelector((state) => state.contadorCreditos);

  return (
    <>
      <Grid item xs={12} md={6} lg={6}>
        <Tile
          title="Usuarios Antiguos"
          list={[
            {
              name: 'Solicitudes',
              value: solicitudesAntiguos?.length || '0',
              loading,
              icon: <Iconify icon={'mdi:account-cash'} color="#006097" width={32} />,
              path: PATH_NAME?.solicitudes_de_usuarios_antiguos,
            },
            {
              name: 'Solicitud Renovaciones',
              value: renovacionAntiguos?.length || '0',
              loading,
              icon: <Iconify icon={'mdi:account-question-outline'} color="#006097" width={32} />,
              path: PATH_NAME?.solicitudes_de_renovaciones_de_usuarios_antiguos,
            },
            {
              name: 'Pre-aprobados sin documentos',
              value: preAprobadosAntiguosSin?.length || '0',
              loading,
              icon: <Iconify icon={'healthicons:i-documents-denied-outline'} color="#B78103" width={32} />,
              path: PATH_NAME?.creditos_preaprobados_sin_documentos_de_usuarios_antiguos,
            },
            {
              name: 'Pre-aprobados con documentos',
              value: preAprobadosAntiguosCon?.length || '0',
              loading,
              icon: <Iconify icon={'healthicons:i-documents-accepted-outline'} color="#B78103" width={32} />,
              path: PATH_NAME?.creditos_preaprobados_con_documentos_de_usuarios_antiguos,
            },
            {
              name: 'Por Aprobar',
              value: paraAprobarAntiguos?.length || '0',
              loading,
              icon: <Iconify icon={'pajamas:approval'} color="#DF3E30" width={32} />,
              path: PATH_NAME?.creditos_por_aprobar_de_usuarios_antiguos,
            },
            {
              name: 'Por Desembolsar',
              value: porDesembolsarAntiguos?.length || '0',
              loading,
              icon: <Iconify icon={'medical-icon:i-waiting-area'} color="#DF3E30" width={32} />,
              path: PATH_NAME?.creditos_por_desembolsar_de_usuarios_antiguos,
            },
            {
              name: 'Reportes Bienestar Financiero',
              value: informesAntiguos?.length || '0',
              loading,
              icon: <Iconify icon={'mdi:report-bell-curve-cumulative'} color="#DF3E30" width={32} />,
              path: PATH_NAME?.informe_bienestar_financiero_antiguos,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Tile
          title="Usuarios Nuevos"
          list={[
            {
              name: 'Solicitudes',
              value: solicitudesNuevos?.length || '0',
              loading,
              icon: <Iconify icon={'mdi:account-cash'} color="#006097" width={32} />,
              path: PATH_NAME?.solicitudes_de_usuarios_nuevos,
            },
            {
              name: 'Solicitud Renovaciones',
              value: renovacionNuevos?.length || '0',
              loading,
              icon: <Iconify icon={'mdi:account-question-outline'} color="#006097" width={32} />,
              path: PATH_NAME?.solicitudes_de_renovaciones_de_usuarios_nuevos,
            },
            {
              name: 'Pre-aprobados sin documentos',
              value: preAprobadosNuevos?.length || '0',
              loading,
              icon: <Iconify icon={'healthicons:i-documents-denied-outline'} color="#B78103" width={32} />,
              path: PATH_NAME?.creditos_preaprobados_sin_documentos_de_usuarios_nuevos,
            },
            {
              name: 'Pre-aprobados con documentos',
              value: preAprobadosNuevosConDocumentos?.length || '0',
              loading,
              icon: <Iconify icon={'healthicons:i-documents-accepted-outline'} color="#B78103" width={32} />,
              path: PATH_NAME?.creditos_preaprobados_con_documentos_de_usuarios_nuevos,
            },

            {
              name: 'Por Aprobar',
              value: paraAprobarNuevos?.length || '0',
              loading,
              icon: <Iconify icon={'pajamas:approval'} color="#DF3E30" width={32} />,
              path: PATH_NAME?.creditos_por_aprobar_de_usuarios_nuevos,
            },
            {
              name: 'Por Desembolsar',
              value: porDesembolsarNuevos?.length || '0',
              loading,
              icon: <Iconify icon={'medical-icon:i-waiting-area'} color="#DF3E30" width={32} />,
              path: PATH_NAME?.creditos_por_desembolsar_de_usuarios_nuevos,
            },
            {
              name: 'Reportes Bienestar Financiero',
              value: informesNuevos?.length || '0',
              loading,
              icon: <Iconify icon={'mdi:report-bell-curve-cumulative'} color="#DF3E30" width={32} />,
              path: PATH_NAME?.informe_bienestar_financiero_nuevos,
            },
          ]}
        />
      </Grid>
    </>
  );
};

// import React, { useState } from 'react';
// import { Box, Button, Card, Typography } from '@mui/material';
// import { saveAs } from 'file-saver';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { useSelector } from 'react-redux';
// import moment from 'moment';

// export default function InformeDavivienda() {
//   const { creditos } = useSelector((state) => state.creditosActivos);
//   console.log("🚀 ~ InformeDavivienda ~ creditos:", creditos.length);
//   const [desde, setDesde] = useState(null);
//   const [hasta, setHasta] = useState(null);

//   const filtro = (e) => {
//     e.preventDefault();

//     const inicial = moment(desde);
//     const final = moment(hasta);

//     const desembolsado = creditos?.filter(
//       (credito) =>
//         moment(credito.fecha_Desembolsado) >= inicial &&
//         moment(credito.fecha_Desembolsado) <= final &&
//         credito?.cancelado === null
//     );

//     let archivo = '\n';

//     // eslint-disable-next-line array-callback-return
//     desembolsado.map((credito) => {
//       let cedula = String(credito?.cuentaUsuario?.cedula);
//       let cuentaDelBanco = credito?.cuentaUsuario?.numero_cuenta.slice(0, 16);
//       let a = `00${credito?.cuentaUsuario?.banco}01${credito?.cuentaUsuario?.cedula}`;
//       while (a.length < 22) {
//         a += ' ';
//       }

//       const con1 = `${a}1 `;
//       while (cuentaDelBanco.length < 16) {
//         cuentaDelBanco = ` ${cuentaDelBanco}`;
//       }

//       let nombreCuenta = `${cuentaDelBanco + credito?.cuentaUsuario?.primerNombre} ${
//         credito?.cuentaUsuario?.primerApellido
//       }`;
//       while (nombreCuenta.length < 43) {
//         nombreCuenta += ' ';
//       }
//       const nombreFiltrado = nombreCuenta.slice(0, 43);
//       const CONnombre = `${con1 + nombreFiltrado}   000000000050000000000`;
//       while (cedula?.length < 13) {
//         cedula += ' ';
//       }
//       let CONcuenta = CONnombre + cedula + credito?.id;

//       while (CONcuenta?.length < 134) {
//         CONcuenta += ' ';
//       }
//       const final = `${CONcuenta}easycredit                    \n`;
//       archivo += final;
//     });
//     const blob = new Blob([archivo], { type: 'text/plain;charset=utf-8' });
//     saveAs(blob, 'davivienda.txt');
//   };

//   return (
//     <LocalizationProvider dateAdapter={AdapterMoment}>
//       <Card sx={{ marginBottom: 4 }}>
//         <Typography variant="h4" sx={{ margin: 4 }}>
//           Informes de Davivienda:
//         </Typography>
//         <Box
//           sx={{
//             maxWidth: 600,
//             margin: '50px auto',
//           }}
//         >
//           <DemoContainer components={['DatePicker', 'DatePicker']}>
//             <DatePicker label="Desde" value={desde} onChange={(newValue) => setDesde(newValue)} />
//             <DatePicker label="Hasta" value={hasta} onChange={(newValue) => setHasta(newValue)} />
//           </DemoContainer>
//           <Button onClick={filtro} sx={{ marginTop: 2 }}>
//             Generar Archivo Plano
//           </Button>
//         </Box>
//       </Card>
//     </LocalizationProvider>
//   );
// }


import React, { useState } from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

import clienteAxios from '../../helper/axios';

export default function InformeDavivienda() {
 const [desde, setDesde] = useState(null);
 const [hasta, setHasta] = useState(null);
 const [loading, setLoading] = useState(false);

 const generarArchivo = (desembolsado) => {
   let archivo = '\n';
   
   desembolsado.forEach((credito) => {
     let cedula = String(credito?.cuentaUsuario?.cedula);
     let cuentaDelBanco = credito?.cuentaUsuario?.numero_cuenta.slice(0, 16);
     let a = `00${credito?.cuentaUsuario?.banco}01${credito?.cuentaUsuario?.cedula}`;
     
     while (a.length < 22) {
       a += ' ';
     }

     const con1 = `${a}1 `;
     while (cuentaDelBanco?.length < 16) {
       cuentaDelBanco = ` ${cuentaDelBanco}`;
     }

     let nombreCuenta = `${cuentaDelBanco + credito?.cuentaUsuario?.primerNombre} ${
       credito?.cuentaUsuario?.primerApellido
     }`;
     while (nombreCuenta.length < 43) {
       nombreCuenta += ' ';
     }
     
     const nombreFiltrado = nombreCuenta.slice(0, 43);
     const CONnombre = `${con1 + nombreFiltrado}   000000000050000000000`;
     
     while (cedula?.length < 13) {
       cedula += ' ';
     }
     
     let CONcuenta = CONnombre + cedula + credito?.id;
     while (CONcuenta?.length < 134) {
       CONcuenta += ' ';
     }
     
     archivo += `${CONcuenta}easycredit                    \n`;
   });

   return archivo;
 };

 const filtro = async (e) => {
   e.preventDefault();
   setLoading(true);

   try {
     const respuesta = await clienteAxios.get('/admin/historicos', {
       params: {
         desde: desde.toISOString(),
         hasta: hasta.toISOString()
       },
       headers: {
         'Content-Type': 'application/json',
         'x-token': localStorage.getItem('token') || '',
       },
     });

     const desembolsado = respuesta.data.historicos?.filter(
       (credito) =>
         moment(credito.fecha_Desembolsado) >= moment(desde) &&
         moment(credito.fecha_Desembolsado) <= moment(hasta) &&
         credito?.cancelado === null
     );

     const archivo = generarArchivo(desembolsado);
     const blob = new Blob([archivo], { type: 'text/plain;charset=utf-8' });
     saveAs(blob, 'davivienda.txt');
   } catch (error) {
     console.error(error);
   } finally {
     setLoading(false);
   }
 };

 return (
   <LocalizationProvider dateAdapter={AdapterMoment}>
     <Card sx={{ marginBottom: 4 }}>
       <Typography variant="h4" sx={{ margin: 4 }}>
         Informes de Davivienda:
       </Typography>
       <Box sx={{ maxWidth: 600, margin: '50px auto' }}>
         <DemoContainer components={['DatePicker', 'DatePicker']}>
           <DatePicker 
             label="Desde" 
             value={desde} 
             onChange={setDesde} 
           />
           <DatePicker 
             label="Hasta" 
             value={hasta} 
             onChange={setHasta} 
           />
         </DemoContainer>
         <Button 
           onClick={filtro} 
           sx={{ marginTop: 2 }}
           disabled={!desde || !hasta || loading}
         >
           {loading ? 'Generando...' : 'Generar Archivo Plano'}
         </Button>
       </Box>
     </Card>
   </LocalizationProvider>
 );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientes: 0,
  clienteEditable: null,
  totalCount: 0,
  currentPage: 1,
};

export const clientesSlice = createSlice({
  name: 'clientes',
  initialState,
  reducers: {
    cargarClientes: (state, action) => {
      state.clientes = action.payload.clientes;
      state.totalCount = action.payload.totalCount;
      state.currentPage = action.payload.currentPage;
      state.loading = false;
    },
    cargarClienteAEditar: (state, action) => {
      state.clienteEditable = action.payload;
    },
    cargarObservacionCliente: (state, action) => {
      state.clienteEditable.notaUsuarios = [...state.clienteEditable.notaUsuarios, action.payload];
    },
  },
});

export const { cargarClientes, cargarClienteAEditar, cargarObservacionCliente } = clientesSlice.actions;

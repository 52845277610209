/* eslint-disable no-irregular-whitespace */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import './ContratoStyle.css';
import { formateador } from '../../../helper/Formateador';
import FirmarContrato from './FirmarContrato';

export default function ContratoLetra() {
  const credito = useSelector((state) => state?.creditoCL?.creditoActivo);
  const cuenta = useSelector((state) => state?.cuentaCL?.cuenta);

  return (
    <>
      <Box sx={{ margin: '0 auto' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>CONTRATO</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="container">
              <span className="negrilla">
                CONTRATO DE MUTUO Y TÉRMINOS DE CONDICIONES DEL CRÉDITO OTORGADO POR EASYCREDIT COLOMBIA S.A.S
              </span>
              <br />
              <br />
              1. PARTES. Por una parte, actúa en calidad de ACREEDOR la sociedad{' '}
              <span className="negrilla">EASYCREDIT COLOMBIA S.A.S</span>, sociedad comercial identificada con el NIT:
              901011172-4, ubicada en la calle 44 # 79-86 of 207, en Medellín, Colombia.Correo Electrónico: soporte
              @easycreditcolombia.com.; por otra parte, actúa en calidad de DEUDOR,
              <span className="negrilla"> {cuenta?.primerNombre} {cuenta?.primerApellido}</span>, identificado(s) con cédula de
              ciudadanía <span className="negrilla"> {cuenta?.cedula}</span>, domiciliado(s) en la <span className="negrilla">{cuenta?.direccion_residencia}</span>. Correo Electrónico:
              <span className="negrilla"> {cuenta?.usuario?.email}</span>
              <br />
              <br />
              2. ANTECEDENTES Y REVELACIÓN DE INFORMACIÓN. EASYCREDIT COLOMBIA es una sociedad que proporciona el
              servicio de préstamos online de libre destinación y a corto plazo. Con EASYCREDIT COLOMBIA, sus clientes
              pueden decidir, sin exceder el cupo de crédito otorgado para el servicio, el monto de recursos requerido
              vía préstamo y el plazo para cancelar dicho monto. Las principales características del servicio prestado
              es su flexibilidad, rapidez y la ausencia de trámites físicos. La utilización de este servicio se puede
              hacer a partir de un monto mínimo de disposición de CIEN MIL PESOS (COP$100.000), hasta un máximo de
              SETECIENTOS CINCUENTA MIL PESOS (COP$750.000) y un plazo de financiación mínimo de 1 día y hasta un máximo de 30
              días, según lo requerido por el cliente. Se trata de créditos únicos, diseñados a la medida de las
              necesidades del cliente y tramitados 100% por Internet, a través de la plataforma predispuesta por la
              entidad para ese fin. El servicio de EASYCREDIT COLOMBIA S.A.S tiene costos asociados y puede afectar la
              historia crediticia de los clientes tomadores de los créditos. El atraso en el pago genera intereses de
              mora, gastos de cobranza y reporte en las centrales de riesgo, conforme a la autorización otorgada por el
              cliente tomar del crédito a la hora de suscribir el presente contrato. EASYCREDIT COLOMBIA S.A.S no
              desarrolla actividades de intermediación o captación de dineros del público u otras actividades similares
              que sean privativas de las instituciones financieras, pues lleva a cabo las labores relacionadas con su
              objeto social con recursos propios. EASYCREDIT COLOMBIA S.A.S NUNCA comparte información de clientes con
              terceras personas o cobra comisiones o gastos por anticipado y no actuamos a través de intermediarios para
              diligenciar, estudiar o agilizar tu solicitud o desembolsar y procesar tu crédito, pues todas estas
              gestiones la adelantamos de manera directa.
              <br />
              <br />
              3.CLÁUSULAS. CLÁUSULA PRIMERA. – OBJETO. Constituye el objeto de este contrato el fijar el cupo máximo de
              préstamo de dinero en mutuo remunerado con interés que podrá hacer el Acreedor al deudor, tomador del
              crédito, atendiendo a lo solicitado por este último en la plataforma provista por el Acreedor para el
              otorgamiento de los recursos correspondientes.
              <br />
              CLAUSULA SEGUNDA.- CONDICIONES.El Deudor declara que conoce y acepta los siguientes términos del (los)
              créditos que él mismo ha solicitado en la plataforma del acreedor. De conformidad con la valoración de la
              información aportada, el acreedor le concede un cupo máximo de crédito al deudor, de acuerdo con su
              calificación, hasta por $ {formateador(credito?.valor_Aprobado)}. Se tendrá como fecha de concesión y
              entrega de los recursos al deudor el {credito?.fecha_Limite_Pago || '______'}, momento en que se
              perfecciona el presente contrato. La fecha de vencimiento será el {credito?.fecha_Limite_Pago || '______'}
              . De conformidad con lo solicitado por el deudor, teniendo como límite el cupo indicado previamente, en
              virtud de este contrato el acreedor le entrega en mutuo remunerado al deudor, un monto total de ${' '}
              {formateador(credito?.valor_Aprobado)} pesos, pagaderos en un plazo máximo de{' '}
              {credito?.dias_Prestamo || '______'} días calendario contabilizados a partir de la fecha de concesión
              indicada previamente a través de la sección “Mi cuenta” en la página web de la entidad o a través de
              correo electrónico, se informan las condiciones específicas del crédito solicitado dentro de las cuales se
              encuentran: (i) Monto del dinero dispuesto (usado).(ii) Interés Remuneratorio: 24% EA.(iii) Interés
              Moratorio: Tasa máxima legalmente permitida, (iv) Fecha de vencimiento: 
              {credito?.fecha_Limite_Pago || '______'} (v) Cargos tecnológicos (vi) Cargos por Gestión del crédito
              (vii) Valor del seguro: $0. EL DEUDOR se obliga irrevocablemente a llevar a cabo el pago del monto del
              capital inherente al crédito otorgado, más los intereses comerciales y moratorios, de acuerdo con su
              causación. Las partes acuerdan que cuando se presenten moras en los pagos de las obligaciones que con este
              contrato se pactan, cualquier pago que efectúe el deudor se imputa primero a intereses de mora, gastos de
              cobranza, si los mismos se han generado, y luego sí a los componentes de la obligación (interés
              remuneratorio, capital y primas de seguros), conforme a lo establecido en la ley civil, articulo 1653 del
              código civil. En caso de existir algún tipo de descuento otorgado por el Acreedor al Deudor, por buen
              manejo de sus productos, éste se perderá en caso de que presente mora en el pago de las obligaciones de
              acuerdo con lo establecido en el numeral <br />
              CLÁUSULA TERCERA. - DEPÓSITO DE DINERO. El Acreedor depositará al deudor el monto del crédito o del
              préstamo solicitado, una vez conocidas y aceptadas las condiciones del mismo por este último a través de
              la sección “Mi cuenta” en la página web de la entidad y del correo electrónico. El desembolso se realizará
              por parte del Acreedor dentro del día hábil siguiente a la confirmación de la aprobación del crédito, en
              el número de cuenta que suministre el Deudor para el efecto, de la cual, sin excepción, debe ser el
              titular, bajo el entendido que EASYCREDIT COLOMBIA S.A.S., de acuerdo con sus políticas de seguridad, no
              realizará ningún desembolso a favor o en cuentas de terceros. Adicionalmente deberá tenerse en cuenta que,
              al plazo anterior, se le debe sumar el término que se demore el banco en el cual tiene su cuenta el deudor
              para completar la transacción.
              <br />
              CLÁUSULA CUARTA. - CONDICIÓN SUSPENSIVA. Las obligaciones del contrato sólo nacerán a las partes una vez
              se verifique que el número de cuenta aportado corresponde a la misma persona que solicitó y se le otorgó
              el crédito, y con la entrega de los recursos correspondientes a dicha cuenta. En el evento el que el
              deudor aporte un número de cuenta de la cual no eres titular, el contrato se entenderá inexistente y no
              nacerán obligaciones de ningún tipo a cargo de EASYCREDIT COLOMBIA S.A.S.
              <br />
              CLÁUSULA QUINTA. COSTOS A CARGO DEL DEUDOR. 5.1. Cargo por Gestión del Crédito: Se genera por efecto de la creación y mantenimiento de un cupo de crédito a tu favor, lo que incluye la firma digital por internet. Esto permite que puedas realizar pagos a través de múltiples canales como PSE (Pago Online) y consignaciones bancarias, lo cual es un beneficio directo para ti. La cuota solo se cobrará una vez que el Deudor autorice el pago, según los términos acordados en su solicitud y trámite de crédito. 
              5.2. Cargos tecnológicos y uso de plataforma: En Easy Credit, buscamos ofrecer una solución integral al brindar acceso ágil a créditos, pero también ofrecemos servicios adicionales como cursos educativos y bienestar financiero. Estos servicios están diseñados para ayudar a los usuarios a desarrollar habilidades financieras y a gestionar mejor su dinero, fomentando su crecimiento personal y económico, aún sin tener historia crediticia o antecedentes de pagos previos, debemos
              realizar grandes inversiones en servicios especializados y desarrollos tecnológicos que nos permitan el
              estudio de los clientes. Teniendo en cuenta lo anterior, se aclara que los costos de uso tecnológico y de
              plataforma, que en definitiva se reflejan en el tipo de producto ofrecido al cliente, será de $ 1.050 pesos
              diarios, el cual será efectuado únicamente al momento de utilizar nuestros servicios y que es el resultado
              de la posibilidad que se le brinda al deudor para el uso de nuestra plataforma digital a la cual se tiene
              acceso sin limitaciones de tiempo o fecha, pudiendo consultar y manejar sus productos como mejor convenga.
              Las partes acuerdan que se hará un solo cargo sin que para el efecto haga diferencia que el Deudor este
              haciendo uso de uno o varios de los productos ofrecidos por el Acreedor, pudiendo hacer los pagos a través
              de la página web por medio de reconocido establecimiento de pago digital.
              <br />
              CLAUSULA SEXTA. - FORMA DE PAGO. El Deudor se obliga a realizar el pago en la fecha establecida,
              realizando la transferencia a través de la página www.easycreditcolombia.com, en la sección “Mi Cuenta”, a
              través de una consignación en una de las cuentas de EASYCREDIT COLOMBIA S.A.S, o mediante las otras
              facilidades de pago a través de PSE (Pago Online) prestadas por el acreedor. El Deudor podrá
              realizar el pago del crédito de manera total o parcial en cualquier fecha anticipada al vencimiento, para
              lo cual, se hará la consecuente liquidación de intereses y cargos al día del pago, y no habrá ningún tipo
              de sanción económica por tal hecho. No obstante lo establecido en este punto (forma de Pago), mediante tu
              aceptación al presente documento el Deudor autoriza al Acreedor, de forma irrevocable, para que el día de
              pago establecido por las partes realice el débito automático de la totalidad o parte del monto adeudado
              (capital, cargos, intereses y cuota de manejo) de la cuenta reportada al momento de la solicitud y/o
              cualquier otra cuenta o cuentas que sean registradas por el Deudor, ya sea al momento de aceptación de las
              condiciones de utilización de los productos del Acreedor o con posterioridad a través de la página de
              Easycredit Colombia S.A.S. o de correo electrónico u otro medio de información. Igualmente el deudor
              autoriza al Acreedor a que lleve a cabo el débito automático de las sumas que se causen por concepto de
              intereses de mora, gastos de cobranza, los perjuicios establecidos en este contrato y los costos que
              puedan surgir en caso de que no existan fondos suficientes en la cuenta o cuentas reportadas el día de la
              realización del débito aquí autorizado para cubrir la totalidad de tus obligaciones o cuando no se reciba
              el pago por cualquiera de los otros de los medios de pago indicados en esta cláusula. Para efectos del
              presente contrato, tengase los siguientes datos de la cuenta del Deudor: ENTIDAD BANCARIA: 
              <span className="negrilla">{cuenta?.banco || '______'}</span>. NO. DE CUENTA:{' '}
              <span className="negrilla">{cuenta?.numero_cuenta || '______'}</span>. TITULAR DE LA CUENTA: 
              <span className="negrilla">
                {cuenta.primerNombre} {cuenta.primerApellido}{' '}
              </span>
              . En caso de no poder realizar el débito automático por no existir fondos depositados en la cuenta o
              cuentas autorizadas o de lograrse únicamente un pago de manera parcial, por no haber depósitos suficientes
              para cubrir la totalidad de las obligaciones, el Acreedor podrá seguir efectuando todos los intentos de
              débito automático que sean necesarios, a la cuenta o cuentas que se encuentren a nombre del deudor, hasta obtener la
              recuperación total de las obligaciones junto con todos los cargos que se mencionan en este punto.
              <br />
              CLAUSULA SÉPTIMA. - EFECTOS DE INCUMPLIMIENTO DE LAS OBLIGACIONES. En caso de presentarse Retardos en el
              Pago el Deudor concurrirá en las siguientes consecuencias: (a) Interés de mora. Deberá pagar los intereses
              de mora correspondientes a los días adicionales que se tarde en realizar el pago a la tasa moratoria
              máxima permitida en Colombia. En caso de que el deudor considere que no va a poder cumplir con el pago en
              la fecha acordada, se le recomienda comunicarse de inmediato con el sistema de Servicio al Cliente de Easy
              Credit, buscando definir un acuerdo sobre la forma como se realizará el pago adecuado de la obligación,
              sin que por tal motivo se tenga la obligación de realizar modificación alguna al crédito o llegar a un
              acuerdo. (b) Gastos de Cobranza. En adición a los intereses de mora, el deudor deberá asumir la totalidad
              de los gastos de cobranza legalmente causados en los que tenga que incurrir el Acreedor para la
              realización de la gestión de cobranza a través de empleados contratados para tal fin o firmas externas
              especializadas. (c) Pérdida de beneficios: El deudor perderá cualquier beneficio previamente otorgado por
              Easy Credit, en calidad de acreedor, principalmente aquellos correspondientes a los descuentos que se
              realizan sobre el producto objeto de utilización o solicitudes de otros productos en materia de tasas de
              interés y cargos a cancelar que hayan sido resultado del buen comportamiento anterior del deudor. En
              consecuencia, en caso de incumplimiento o mora, el Acreedor podrá exigirle al deudor el pago del monto del
              crédito, sus intereses remuneratorios y de mora, así como los cargos correspondientes, haciendo caso omiso
              de cualquier descuento ofrecido, no obstante que los valores indicados y aceptados al momento de la
              solicitud así los haya contemplado. (d). Pérdida del cupo de crédito. Entrado en mora el deudor, el
              Acreedor podrá, de manera unilateral, disminuir el valor o aún más dar por terminado el contrato de cupo
              de crédito que había sido previamente otorgado a favor del deudor. (e). Aceleración y Vencimiento de todas
              las obligaciones a cargo del deudor. La mora en el pago de cualquiera de las obligaciones a cargo del
              deudor, facultará al Acreedor a declarar de manera automática el vencimiento e inmediata exigibilidad de
              todas las obligaciones que en cabeza del Deudor para con el Acreedor, en uso de cualquiera de los
              productos ofrecidos por este último. (f). Reporte en Centrales de información de riesgo crediticio: En
              caso de no realizar el pago a más tardar 20 días calendario después de haber sido informado sobre el
              particular, la mora en el pago de obligación del Deudor será reportada a las centrales de riesgo
              crediticio, sin perjuicio de las demás acciones que el Acreedor pueda adelantar para el cobro de las
              obligaciones a su favor. La comunicación de aviso de reporte a las centrales de riesgo, será remitida al
              Deudor de manera escrita, mediante comunicación dirigida al domicilio al domicilio registrado en las bases
              de datos o por cualquier tipo de mensaje de datos incluyendo SMS, correo electrónico o cualquier medio del
              que quede registro para posteriores consultas. <br />
              CLAUSULA OCTAVA. - AUTORIZACIÓN DE TRATAMIENTO DE INFORMACIÓN PERSONAL Y REPORTE A CENTRALES DE RIESGO. El
              Deudor autoriza expresamente al acreedor, con la firma del presente contrato, o a quien lo represente u
              ostente en el futuro la calidad de acreedor, a reportar, consultar, procesar, solicitar y divulgar a las
              centrales de riesgo financiero, todo asunto relacionado con la obligación adquirida con base en este
              contrato. El deudor autoriza a Easycredit Colombia S.A.S. y a quien en el futuro ostente la calidad de
              acreedor para: (1) Realizar ante los operadores de información (Datacrédito, Cifin, procredito operado por
              FENALCO y/o cualquier otra entidad que llegue a manejar bases de datos con los mismos objetivos), las
              siguientes actividades: (a) Reportar con fines estadísticos, de control, supervisión, evaluación de
              riesgos y demás que señalen las políticas de Easycredit Colombia S.A.S. y/o las normas vigentes
              colombianas, tus datos personales (incluyendo financi eros y comerciales) y la información relacionada con
              el surgimiento de cualquier obligación, novedad, modificación, extinción, cumplimiento o incumplimiento de
              las obligaciones contraídas o que llegues a contraer en favor de Easycredit Colombia S.A.S. y b) Reportar
              cualquier otro dato que se estime pertinente con relación a la existencia de deudas vencidas sin cancelar
              o con la utilización indebida de los productos y/o servicios prestados u ofrecidos por Easycredit Colombia
              S.A.S.. (2) Solicitar y consultar a los operadores de información tus datos (incluyendo financieros y
              comerciales) con el fin de: a) Obtener información referente a las relaciones comerciales establecidas con
              cualquier entidad financiera o comercial y b) Confirmar datos para iniciar o mantener una relación
              contractual. Como nuestro cliente entiendes que la permanencia de la información del dato positivo o
              negativo, según sea el caso, será la que la legislación señale. (3) Igualmente, de manera expresa autoriza
              el Deudor a Easycredit Colombia S.A.S. a utilizar, compartir e intercambiar con sus entidades filiales,
              matrices, aliadas y/o con entidades financieras, tu información personal contenida en las bases de datos
              de la entidad con fines de control de riesgos, comerciales, estadísticos y la realización de actividades
              de mercadeo de sus servicios y publicidad. (4) También autoriza el Deudor expresamente al Acreedor o quien
              haga sus veces, para que contrate con terceros localizados en Colombia o en el exterior, servicios
              relacionados con el procesamiento de tus datos con las finalidades autorizadas por este medio. El Deudor
              declara conocer que ese proceso puede implicar la recolección, archivo, procesamiento y transmisión de
              dicha información entre compañías vinculadas o relacionadas contractualmente con Easycredit Colombia
              S.A.S. localizadas dentro o fuera de Colombia. En todo caso, esas entidades estarán igualmente sujetas a
              las mismas obligaciones de confidencialidad en el manejo de la información a que está sujeto el acreedor,
              con las limitaciones legales impuestas por las leyes aplicables sobre la materia, en la jurisdicción donde
              ésta se recolecte, archive, procese o transmita. (5) El deudor autoriza igualmente a que se le contacte
              con fines comerciales y promocionales ya sea sobre sus propios servicios y productos, o los de terceros
              con los que Easycredit Colombia S.A.S. tenga relaciones comerciales o alianzas, a través de correo,
              teléfono, celular, correo electrónico o cualquier otro medio conocido o por conocer, a través del cual se
              te pueda contactar. (6) Cuando el deudor envíe Comentarios, artículos, fotos, videos y otros contenidos a
              la página de internet del acreedor, se entenderá que los derechos de propiedad intelectual seguirán siendo
              de este y, en caso de incluir cualquier tipo de información personal propia o de terceros. Easycredit
              Colombia S.A.S. no se hace responsable por ninguno de los contenidos que entregue el Deudor, aunque si se
              reserva los derechos para publicarlos o eliminarlos, de conformidad con su política de tratamiento de
              datos personales y validando las autorizaciones aquí otorgadas por el Deudor. Para tales efectos, el
              Deudor autoriza al Acreedor a que, al momento de subir contenido correspondiente a imágenes, comentarios,
              fotografías, videos etc., a la página de internet o entregarlo por otros medios, este pueda presentarlo en
              su página de internet, Facebook, Twitter y otros medios de comunicación y utilizarlos como parte de sus
              campañas comerciales o de mercadeo dirigidas al público en general a través de distintos medios de
              comunicación. Se le recuerda al Deudor que puede ejercer sus derechos relacionados con el habeas data,
              incluyendo la posibilidad de solicitar la actualización, modificación o supresión de su información ante
              Easycredit Colombia S.A.S. contactándose a través del siguiente correo electrónico:
              soporte@easycreditcolombia.com o por vía telefónica al 3112384 en Medellín. En cualquier caso, el Deudor
              podrá efectuar los Cambios en la información suministrada, teniendo en cuenta que como cliente se
              compromete a notificarle al Acreedor de cualquier cambio en la información suministrada en el proceso de
              aplicación o durante la duración de la relación comercial entre las partes. En la página web del Acreedor
              tendrá la opción de modificar, actualizar o darse de baja. <br />
              CLÁSULA NOVENA. DECLARACIÓN SOBRE VERACIDAD DE LA INFORMACIÓN. Mediante la aceptación del presente
              documento, el Deudor declara que la información compartida con Easycredit Colombia S.A.S. es completa,
              actual y veraz, resultando responsable de cualquier disconformidad entre los datos entregados y la
              realidad, bajo la premisa que en tal caso se podría materializar conductas tipificadas penalmente como la
              estafa, falsedad en documento privado y/o falsedad personal los cuales se encuentran penalizados de
              acuerdo con lo establecido al respecto por los artículos 246, 289 y 296 del Código Penal Colombiano. Así
              mismo, declara el Deudor que los recursos solicitados y objeto del contrato serán usados para fines
              lícitos y de ninguna manera para la comisión de cualquiera de los actos establecidos como delitos de
              acuerdo con la misma legislación penal.
              <br />
              CLÁUSULA DÉCIMA. CLAUSULA PENAL SANCIONATORIA. En adición a las sanciones penales a las que se puedes ver
              avocado el deudor por los delitos mencionados previamente, en caso de no brindar a Easycredit Colombia
              S.A.S. información real y veraz, o en aquellos eventos en que no actualice adecuadamente su información
              dentro del transcurso de la vigencia de la relación comercial, pactan las partes que se causara una
              sanción pecuniaria a cargo del Deudor y a favor de Easycredit Colombia S.A.S. que ascenderá un valor
              correspondiente al cien por ciento (100%) de la suma adeudada por cualquier concepto, más los impuestos
              generados por este concepto. La sanción aquí estimada podrá ser cobrada junto con el capital, intereses
              remuneratorios, intereses de mora y demás gastos, perjuicios, incluidos los de cobranza, que surjan de la
              obligación incumplida, bajo el entendido que esta sanción y los otros cobros señalados no resultan
              incompatibles al tener surgimiento en dos situaciones perfectamente distinguibles, los unos de la falta de
              veracidad o actualidad de la información y los otros del contrato celebrado y sus costos conexos. CLÁUSULA
              DÉCIMA PRIMERA. TRANSACCIONES ADICIONALES. El deudor declara entender y aceptar que el presente documento
              se entiende como contrato de mutuo y como formulario de vinculación a los servicios de Easycredit Colombia
              S.A.S. y que, en tal virtud, deberá leerlo cuidadosamente antes de aceptarlo a través de los medios
              electrónicos puestos a su disposición para el efecto, así como que las condiciones para cada nueva
              transacción que realice deberás aceptarlas en su momento a través de la página web de Easycredit Colombia
              S.A.S. o del correo electrónico establecido para el envío y recepción de comunicaciones. Easy Credit S.A.S
              se reserva el derecho a solicitar y verificar información adicional de cualquier tipo, en cualquier
              momento durante el proceso de solicitud del crédito y hasta el momento de su desembolso. CLÁUSULA DÉCIMA
              SEGUNDA. - DISPOSICIONES FINALES. Este es un contrato a distancia, enmarcado en la Ley de Comercio
              Electrónico 527 de 1999. El DEUDOR acepta que a través de los mecanismos electrónicos utilizados en la
              plataforma de Easycredit Colombia S.A.S., suscribirá este contrato y que mediante el ingreso de los dos
              (2) códigos que se le envían separadamente por email y SMS, como mensaje de datos, el DEUDOR acepta el
              contenido integral de este instrumento y que por tanto no se puedes oponer a lo aquí estipulado una vez lo
              acepte. AL ACEPTAR LOS TÉRMINOS Y CONDICIONES DEL PRESENTE DOCUMENTO EL DEUDOR DECLARA QUE ACEPTA DE
              MANERA EXPRESA E INEQUIVOCA LOS TÉRMINOS Y CONDICIONES DEL CRÉDITO Y LAS DEMAS ESTIPULACIONES PREVISTAS EN
              ESTE ACUERDO. En constancia de haber leído, entendido y aceptado todo lo anterior, EL DEUDOR firma
              mediante la inserción de los códigos únicos enviados al celular y a el correo electrónico registrados,
              aceptando este documento de manera electrónica a través de la plataforma tecnológica en el sitio
              https://www.easycreditcolombia.com/admin_usuarios/login.
              <br />
              <br />
              En mi calidad de DEUDOR declaro expresamente que: 1. Tanto mi actividad, profesión u oficio es licito y la
              ejerzo dentro del marco legal y los recursos que poseo no provienen de actividades ilícitas de las
              contempladas en el Código Penal Colombiano. 2. La información que he suministrado en la solicitud y en
              este documento es veraz y verificable y me comprometo a actualizarla anualmente. 3. En cumplimiento de lo
              establecido en prevención del Lavado de Activos y Financiación del Terrorismo, declaro que el origen de
              fondos y/o bienes de mi propiedad proviene de las actividades enunciadas en este documento.
              <br />
              FIRMA ELECTRONICA CLIENTE _________________________ (código de validación de identidad).
              <br /> <br />
              <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>PAGARÉ A LA ORDEN</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Ciudad , Fecha de Creación: {credito.fecha_Desembolsado || '______'}. No.
              {credito.fecha_Desembolsado || '______'} Por ${formateador(credito.valor_Aprobado) || '______'},
              identificados como aparece al pie de nuestras firmas declaramos:
              <br />
              <span className="negrilla">PRIMERA.</span> OBJETO-Que por virtud del PRESENTE TÍTULO VALOR PAGAREMOS
              INCONDICIONALMENTE, A LA ORDEN DE EASYCREDIT COLOMBIA S.A.S., identificada con NIT 901011172-4 registrada
              en la Cámara de Comercio de Medellín, en la ciudad de Medellín en la dirección Calle 44 No. 79-86 Of. 207,
              en las fechas de amortización por cuotas señaladas en la cláusula segunda de este mismo pagaré, la suma de
              __________________________________ ($_______________).
              <br />
              <span className="negrilla">SEGUNDA.</span> PLAZO- Que pagaremos la suma indicada en la cláusula anterior
              mediante cuotas (mensuales, quincenales, semanales) ______________ sucesivas correspondientes cada una a
              la cantidad de _______________________ ($_________). La primera cuota se efectuará el día ____ de
              ___________ de 200__.
              <br />
              <span className="negrilla">TERCERA.</span> INTERESES- Sobre las cuotas o instalamentos insolutos, se
              generará a partir de su vencimiento un interés moratorio del ______% (o la tasa máxima autorizada por la
              Superintendencia Bancaria).
              <br />
              <span className="negrilla">CUARTO.</span>
              CLÁUSULA ACELERATORIA- El tenedor podrá declarar insubsistente los plazos de esta obligación o de las
              cuotas que constituyen el saldo y exigir su pago inmediato judicial o extrajudicialmente en los siguientes
              casos: a) Cuando los deudores incumplan una cualquiera de las obligaciones derivadas del presente
              documento, y b) Cuando los deudores inicien trámite de liquidación obligatoria o voluntaria, se sometan a
              proceso concordatario o convoquen a concurso de acreedores.
              <br />
              <span className="negrilla">QUINTA.</span> IMPUESTO DE TIMBRE- Los gastos originados por concepto de
              impuesto de timbre correrán a cargo de los deudores.
              <br />
              <span className="negrilla">SEXTO.</span> HONORARIOS- En el evento de incumplir o quedar en mora con
              cualquiera de las obligaciones crediticias adquiridas en este título, acepto pagar los honorarios que se
              le generen a mi acreedor por concepto del cobro prejurídico o judicial que tenga que inciar en mi contra,
              así como los gastos y costas que se le generen por el retiro y/o actualización de las Bases de Datos en la
              que me encuentre reportado por causa de mi incumplimiento o mora. EASY CREDIT COLOMBIA S.A.S 901.011.172-4
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography> HOJA DE INSTRUCIONES</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span className="negrilla">
                {cuenta.primerNombre} {cuenta.primerApellido}
              </span>
              , identificados como aparece al pie de nuestras firmas, autorizamos a EASYCREDIT COLOMBIA SAS con NIT
              número 901.011.172-4 para que, haciendo uso de las facultades conferidas en el articulo 622 del Código de
              Comercio, llene los espacios que se han dejado en blanco en _________________________(el pagaré o la letra
              de cambio) número _consecutivo______, para lo cual deberá ceñirse a las siguientes instrucciones:
              Autorizamos para que se llene el nombre del (los) deudor (es), domicilio, Cédula. El monto será igual al
              valor de todas las obligaciones exigibles que a cargo nuestro y en favor de _________________________
              existan al momento de ser llenados los espacios. Los espacios en blanco se llenarán cuando ocurra una
              cualquiera de las siguientes circunstancias: Mora en el pago de las obligaciones de ______ o más cuotas.
              Cuando el pago mediante cheque salga sin suficientes fondos. La fecha será aquella en que se llenen los
              espacios en blanco. La ciudad será Medellín. Los interesés moratorios serán del 3% mensual, contados a
              partir de la completación del _________________ (pagaré o letra de cambio). Firmado en la ciudad de
              Medellín a los _____ días del mes de _____________ de ________. EASY CREDIT COLOMBIA S.A.S 901.011.172-4
            </Typography>
          </AccordionDetails>
        </Accordion>
        <FirmarContrato />
      </Box>
    </>
  );
}

/* eslint-disable radix */
import moment from 'moment';
import {
  DIAS_MAXIMO_COBROS,
  DIAS_PRIMERA_COBRANZA,
  DIAS_SEGUNDA_COBRANZA,
  IVA,
  PORCENTAJE_INTERES_MORATORIO,
  PORCENTAJE_SEGUNDA_COBRANZA,
  VALOR_PLATAFORMA,
  VALOR_PRIMER_COBRANZA,
} from '../constants/constans';

const hoy = moment().format();

function convertirFechaAColombia(fechaUTC) {
  const fecha = new Date(fechaUTC);
  return fecha.toLocaleString('es-CO', { timeZone: 'America/Bogota' });
}

export const isCreditoVencido = (fechaDeVencimiento) => fechaDeVencimiento.isBefore(hoy);

const diasVencidos = (fechaLimitePago) => Math.abs(moment(fechaLimitePago).diff(hoy, 'days'));

const diasTranscurridos = (fechaLimitePago) => Math.abs(moment(fechaLimitePago).diff(hoy, 'days'));

function cobroPlataforma(fechaDesembolso, fechaLimitePago) {
  if (diasTranscurridos(fechaDesembolso) === 0) {
    return 1 * VALOR_PLATAFORMA;
  }
  if (diasVencidos(fechaLimitePago) >= DIAS_MAXIMO_COBROS) {
    return DIAS_MAXIMO_COBROS * VALOR_PLATAFORMA;
  }

  return diasTranscurridos(fechaDesembolso) * VALOR_PLATAFORMA;
}

function cobroAdministracion(prestamo) {
  let cobro;
  if (prestamo <= 150000) {
    cobro = 25000;
  } else if (prestamo >= 151000 && prestamo <= 300000) {
    cobro = 25000;
  } else if (prestamo >= 301000 && prestamo <= 400000) {
    cobro = 29000;
  } else if (prestamo >= 401000 && prestamo <= 500000) {
    cobro = 31000;
  } else if (prestamo >= 501000 && prestamo <= 750000) {
    cobro = 36000;
  }
  return cobro;
}

function cobroIva(administracion, plataformaWeb) {
  return (Number(administracion) + Number(plataformaWeb)) * IVA;
}

function primerCobranzaFNH(fechaLimitePago) {
  if (diasVencidos(fechaLimitePago) >= DIAS_PRIMERA_COBRANZA) {
    return VALOR_PRIMER_COBRANZA;
  }
  return 0;
}

function intereses(fechaDesembolso, valor) {
  const diasCorrientes = diasTranscurridos(fechaDesembolso);
  let dias;
  if (diasCorrientes >= 30) {
    dias = 30;
  } else if (diasCorrientes <= 0) {
    dias = 1;
  } else {
    dias = diasCorrientes;
  }

  const interes = {
    dia1: 0.000620035,
    dia2: 0.000620228,
    dia3: 0.00062042,
    dia4: 0.000620612,
    dia5: 0.000620805,
    dia6: 0.000620997,
    dia7: 0.000621201,
    dia8: 0.000621383,
    dia9: 0.000621575,
    dia10: 0.000621768,
    dia11: 0.000622009,
    dia12: 0.000622154,
    dia13: 0.000622412,
    dia14: 0.000622618,
    dia15: 0.000622734,
    dia16: 0.000622997,
    dia17: 0.000623148,
    dia18: 0.000623314,
    dia19: 0.000623701,
    dia20: 0.000623701,
    dia21: 0.000623929,
    dia22: 0.000624186,
    dia23: 0.000624477,
    dia24: 0.000624477,
    dia25: 0.000624809,
    dia26: 0.000625194,
    dia27: 0.000625194,
    dia28: 0.0006256421707169,
    dia29: 0.0006256421707169,
    dia30: 0.000625642,
  };
  return valor * interes[`dia${dias}`] * dias;
}

function segundaCobranzaFNH(fechaLimitePago, credito) {
  if (diasVencidos(fechaLimitePago) >= DIAS_SEGUNDA_COBRANZA) {
    return credito * PORCENTAJE_SEGUNDA_COBRANZA;
  }
  return 0;
}

export function diasDeMora(fechaLimitePago) {
  return Math.abs(moment(fechaLimitePago).diff(hoy, 'days'));
}

function interesMoratorioFNH(credito = []) {
  const diasMoratorios = diasVencidos(credito?.fecha_Limite_Pago);
  const administrativos =
    cobroAdministracion(credito?.valor_Aprobado) +
    cobroIva(
      cobroAdministracion(credito?.valor_Aprobado),
      cobroPlataforma(credito?.fecha_Desembolsado, credito?.fecha_Limite_Pago)
    ) +
    cobroPlataforma(credito?.fecha_Desembolsado, credito?.fecha_Limite_Pago) +
    intereses(credito?.fecha_Desembolsado, credito?.valor_Aprobado) +
    primerCobranzaFNH(credito?.fecha_Limite_Pago) +
    segundaCobranzaFNH(credito?.fecha_Limite_Pago, credito?.valor_Aprobado);

  if (diasMoratorios > 31) {
    return PORCENTAJE_INTERES_MORATORIO * diasMoratorios * administrativos;
  }
  return 0;
}

export const calculosDelCredito = (credito = []) => ({
  montoAprobando: credito?.valor_Aprobado?.toFixed(0),
  interesPorMora: parseInt(interesMoratorioFNH(credito)).toFixed(0),
  interesCorriente: parseInt(intereses(credito?.fecha_Desembolsado, credito?.valor_Aprobado)).toFixed(0),
  valorPlataforma: parseInt(cobroPlataforma(credito?.fecha_Desembolsado, credito?.fecha_Limite_Pago)).toFixed(0),
  valorAdministracion: cobroAdministracion(credito?.valor_Aprobado)?.toFixed(0),
  valorIva: cobroIva(
    cobroAdministracion(credito?.valor_Aprobado),
    cobroPlataforma(credito?.fecha_Desembolsado, credito?.fecha_Limite_Pago)
  ).toFixed(0),
  valorPrimerCobranza: primerCobranzaFNH(credito?.fecha_Limite_Pago).toFixed(0),
  valorSegundaCobranza: segundaCobranzaFNH(credito?.fecha_Limite_Pago, credito?.valor_Aprobado)?.toFixed(0),
  valorTotalParaPago: (
    credito?.valor_Aprobado +
    parseInt(interesMoratorioFNH(credito)) +
    parseInt(intereses(credito?.fecha_Desembolsado, credito?.valor_Aprobado)) +
    parseInt(cobroPlataforma(credito?.fecha_Desembolsado, credito?.fecha_Limite_Pago)) +
    cobroAdministracion(credito?.valor_Aprobado) +
    cobroIva(
      cobroAdministracion(credito?.valor_Aprobado),
      cobroPlataforma(credito?.fecha_Desembolsado, credito?.fecha_Limite_Pago)
    ) +
    primerCobranzaFNH(credito?.fecha_Limite_Pago) +
    segundaCobranzaFNH(credito?.fecha_Limite_Pago, credito?.valor_Aprobado)
  ).toFixed(0),
  valorTotalPagoRenovacion: (
    parseInt(intereses(credito?.fecha_Desembolsado, credito?.valor_Aprobado)) +
    parseInt(cobroPlataforma(credito?.fecha_Desembolsado, credito?.fecha_Limite_Pago)) +
    cobroAdministracion(credito?.valor_Aprobado) +
    cobroIva(
      cobroAdministracion(credito?.valor_Aprobado),
      cobroPlataforma(credito?.fecha_Desembolsado, credito?.fecha_Limite_Pago)
    )
  ).toFixed(0),
});

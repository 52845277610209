// import { IconButton, Tooltip } from '@mui/material';
// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { cargarCreditoAEditar } from '../../store';
// import { listadoHistoricos } from '../../helper/coleccionCreditos';
// import TableList from '../table-list/TableList';
// import { MAIN_PATH, PATH_NAME } from '../../constants';
// import Iconify from '../iconify/Iconify';

// export const Historico = () => {
//   const { creditos, loading } = useSelector((state) => state.historicos);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const data = listadoHistoricos(creditos);

//   const redireccionarEdicion = (idCredito) => {
//     const credito = creditos.filter((credito) => credito.id === idCredito)[0];
//     if (credito === undefined) {
//       return;
//     }
//     dispatch(cargarCreditoAEditar(credito));
//     navigate(`/admin/${MAIN_PATH}/${PATH_NAME.visualizar_credito_historico}`, { replace: true });
//   };

//   const columnas = [
//     {
//       label: 'Nombre',
//       name: 'nombre',
//       options: {
//         filter: true,
//       },
//     },
//     {
//       label: 'Cedula',
//       name: 'cedula',
//       options: {
//         filter: true,
//       },
//     },
//     {
//       label: 'N. Crédito',
//       name: 'creditoId',
//       options: {
//         filter: true,
//       },
//     },
//     {
//       label: 'Monto Aprobado',
//       name: 'valor_Aprobado',
//       options: {
//         filter: true,
//       },
//     },
//     {
//       label: 'Fecha Cancelado',
//       name: 'fecha_Cancelado',
//       options: {
//         filter: true,
//       },
//     },
//     {
//       name: 'Gestionar',
//       options: {
//         sort: false,
//         customBodyRender: (_value, tableMeta) => (
//           <Tooltip title="Gestionar" aria-label="Gestionar">
//             <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[2])}>
//               <Iconify icon="material-symbols:edit-square-outline" />
//             </IconButton>
//           </Tooltip>
//         ),
//       },
//     },
//   ];

//   return <TableList titulo="Historico de Créditos" columnas={columnas} data={data} loading={loading} />;
// };


import { IconButton, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cargarCreditoAEditar } from '../../store';
import { startConsultarCreditosPaginados } from '../../store/historicos';
import { listadoHistoricos } from '../../helper/coleccionCreditos';
import { MAIN_PATH, PATH_NAME } from '../../constants';
import Iconify from '../iconify/Iconify';
import TableListPagination from '../table-list/TableListPagination';

export const Historico = () => {
 const { creditos, loading, paginacion } = useSelector((state) => state.historicos);
 const [currentPage, setCurrentPage] = useState(1);
 const [searchTerm, setSearchTerm] = useState('');
 const navigate = useNavigate();
 const dispatch = useDispatch();
 
 useEffect(() => {
   dispatch(startConsultarCreditosPaginados(currentPage, searchTerm));
 }, [dispatch, currentPage, searchTerm]);

 const handlePageChange = (page) => {
   setCurrentPage(page);
 };

 const handleSearch = (term) => {
   setSearchTerm(term);
   setCurrentPage(1);
 };

 const data = listadoHistoricos(creditos);

 const redireccionarEdicion = (idCredito) => {
   const credito = creditos.filter((credito) => credito.id === idCredito)[0];
   if (credito === undefined) {
     return;
   }
   dispatch(cargarCreditoAEditar(credito));
   navigate(`/admin/${MAIN_PATH}/${PATH_NAME.visualizar_credito_historico}`, { replace: true });
 };

 const columnas = [
   {
     label: 'Nombre',
     name: 'nombre',
     options: {
       filter: true,
     },
   },
   {
     label: 'Cedula',
     name: 'cedula',
     options: {
       filter: true,
     },
   },
   {
     label: 'N. Crédito',
     name: 'creditoId',
     options: {
       filter: true,
     },
   },
   {
     label: 'Monto Aprobado',
     name: 'valor_Aprobado',
     options: {
       filter: true,
     },
   },
   {
     label: 'Fecha Cancelado',
     name: 'fecha_Cancelado',
     options: {
       filter: true,
     },
   },
   {
     name: 'Gestionar',
     options: {
       sort: false,
       customBodyRender: (_value, tableMeta) => (
         <Tooltip title="Gestionar" aria-label="Gestionar">
           <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[2])}>
             <Iconify icon="material-symbols:edit-square-outline" />
           </IconButton>
         </Tooltip>
       ),
     },
   },
 ];

 return (
  <TableListPagination 
    titulo="Histórico de Créditos" 
    columnas={columnas} 
    data={data} 
    loading={loading}
    onPageChange={handlePageChange}
    onSearch={handleSearch}
    currentPage={currentPage}
    totalCount={paginacion?.total || 0} 
  />
 );
};
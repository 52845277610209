/* eslint-disable radix */

import moment from 'moment';
import { formateador } from './Formateador';
import { diasMoraFNH, valoresPagados } from './funtions';

const getEstado = (credito) => {
  if (credito.adquirido === true && credito.aprobado === null && credito.preAprobado === true) {
    return 'Pendiente por Aprobar';
  }
  if (credito.solicitud_Credito === true && credito.preAprobado === null) {
    return 'En Estudio';
  }

  if (credito.adquirido === true && credito.aprobado === true && credito.fecha_Firma === null) {
    return 'Pendiente Firma';
  }

  if (credito.cancelado === true && credito.desembolsado === true) {
    return 'Cancelado';
  }

  if (credito.solicitar_Documentos === true && credito.desembolsado === null) {
    return 'Pendiente Documentos';
  }

  if (credito.adquirido === true && credito.aprobado === true && credito.desembolsado === null) {
    return 'Pendiente Desembolso';
  }

  if (credito.adquirido === true && credito.aprobado === true && credito.desembolsado === true) {
    return 'Desembolsado';
  }

  return '?';
};

const getLabelDiasVencido = (dias) => {
  dias = Math.abs(dias);
  if (dias === 0) return 'Por Vencer';
  if (dias >= 2) {
    return `${dias} días`;
  }
  return `${dias} día`;
};

export const coleccionCreditosActivos = (data = []) =>
  data.map((credito) => ({
    nombre: `${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.primerApellido}`,
    cedula: credito?.cuentaUsuario?.cedula,
    id: credito?.id,
    valorSolicitado:
      credito?.valor_Aprobado === null
        ? `$ ${formateador(credito?.valor_Solicitado)}`
        : `$ ${formateador(credito?.valor_Aprobado)}`,
    estado: getEstado(credito),
    Gestionar: 1,
  }));

export const coleccionInformeBienestar = (data = []) =>
  data.map((credito) => ({
    nombre: `${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.primerApellido}`,
    cedula: credito?.cuentaUsuario?.cedula,
    id: credito?.id,
  }));

export const coleccionCreditosActivosDesembolsados = (data = []) =>
  data
    .filter((credito) => credito.desembolsado === true)
    .map((credito) => ({
      nombre: `${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.primerApellido}`,
      cedula: formateador(credito?.cuentaUsuario?.cedula),
      id: credito?.id,
      valor_Aprobado: credito?.valor_Aprobado === null ? '-' : `$ ${formateador(credito?.valor_Aprobado)}`,
      estado: getEstado(credito),
      Gestionar: 1,
    }));

export const coleccionCreditos = (data = []) =>
  data.map((credito) => ({
    nombre: `${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.primerApellido}`,
    cedula: formateador(credito?.cuentaUsuario?.cedula),
    id: credito?.id,
    valorSolicitado: credito?.valor_Aprobado === null ? '-' : `$ ${formateador(credito?.valor_Aprobado)}`,
    fechaSolicitado: moment(credito?.fecha_Solicitado).tz('America/Bogota').format('l'),
    fechaRechazado: moment(credito?.fecha_Rechazado).tz('America/Bogota').format('l'),
    fechaDesembolsados: moment(credito?.fecha_Desembolsado).tz('America/Bogota').format('l'),
    fechaRenovado: moment(credito?.fecha_Desembolsado).tz('America/Bogota').format('l'),
    fechaCancelado: moment(credito?.fecha_Cancelado).tz('America/Bogota').format('l'),
  }));

export const coleccionCreditosVencidos = (creditos = []) =>
  creditos
    .map((credito) => ({
      nombre: `${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.primerApellido}`,
      cedula: formateador(credito?.cuentaUsuario?.cedula),
      id: credito?.id,
      valorSolicitado: credito?.valor_Aprobado === null ? '-' : `$ ${formateador(credito?.valor_Aprobado)}`,
      estado: 1,
      mora: diasMoraFNH(credito?.fecha_Limite_Pago),
      limitePago: moment(credito?.fecha_Limite_Pago).format('l'),
      diasMora: getLabelDiasVencido(diasMoraFNH(credito?.fecha_Limite_Pago)),
      compromiso: credito?.compromisoCreditos?.length !== 0,
    }))
    .sort((a, b) => a.mora - b.mora);

export const coleccionCreditosPorAsentarPagos = (creditos = []) =>
  creditos
    .filter((credito) => credito?.valor_Cancelado_Capital !== null && credito?.fecha_Cancelado !== null)
    .map((credito) => ({
      nombre: `${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.primerApellido}`,
      cedula: formateador(credito?.cuentaUsuario?.cedula),
      id: credito?.id,
      valorSolicitado: credito?.valor_Aprobado === null ? '-' : `$ ${formateador(credito?.valor_Aprobado)}`,
      fechaPago: moment(credito?.fecha_Cancelado).format('l'),
      valorCancelado: `$ ${formateador(valoresPagados(credito))}`,
    }))
    .sort((a, b) => b.mora - a.mora);

export const listaCompromisos = (compromisos) =>
  compromisos
    .filter((compromiso) => compromiso?.cumplio_Compomiso === null)
    .map((compromiso) => ({
      creditoId: compromiso?.creditoId,
      fecha_Compromiso: moment(compromiso?.fecha_Compromiso).format('l'),
      nota_Compomiso: compromiso?.nota_Compomiso,
      monto: `$ ${formateador(compromiso?.valor_Compromiso)}`,
      estado: moment().isAfter(compromiso?.fecha_Compromiso),
    }));
export const listaCompromisosPorAsentar = (compromisos) =>
  compromisos
    .filter((compromiso) => compromiso.fecha_Pago_Compromiso && !compromiso.cumplio_Compomiso)
    .map((compromiso) => ({
      creditoId: compromiso?.creditoId,
      fecha_Compromiso: moment(compromiso?.fecha_Compromiso).format('l'),
      nota_Compomiso: compromiso?.nota_Compomiso,
      monto: `$ ${formateador(compromiso?.valor_Compromiso)}`,
      estado: moment().isAfter(compromiso?.fecha_Compromiso),
    }));

export const listaCompromisosPorVencer = (compromisos) =>
  compromisos
    .filter(
      (compromiso) => compromiso?.cumplio_Compomiso === null && moment().isSame(compromiso?.fecha_Compromiso, 'day')
    )
    .map((compromiso) => ({
      creditoId: compromiso?.creditoId,
      fecha_Compromiso: moment(compromiso?.fecha_Compromiso).format('l'),
      nota_Compomiso: compromiso?.nota_Compomiso,
      monto: `$ ${formateador(compromiso?.valor_Compromiso)}`,
    }));

export const listaCompromisosVencidos = (compromisos) =>
  compromisos
    .filter((compromiso) => compromiso?.cumplio_Compomiso === null && moment().isAfter(compromiso?.fecha_Compromiso))
    .map((compromiso) => ({
      creditoId: compromiso?.creditoId,
      fecha_Compromiso: moment(compromiso?.fecha_Compromiso).format('l'),
      nota_Compomiso: compromiso?.nota_Compomiso,
      monto: `$ ${formateador(compromiso?.valor_Compromiso)}`,
    }));

// export const listadoHistoricos = (creditos) =>
//   creditos.map((credito) => ({
//     nombre: `${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.primerApellido}`,
//     cedula: formateador(credito?.cuentaUsuario?.cedula),
//     creditoId: credito?.id,
//     valor_Aprobado: credito?.valor_Aprobado === null ? '-' : `$ ${formateador(credito?.valor_Aprobado)}`,
//     fecha_Cancelado: credito.fecha_Cancelado
//       ? moment(credito?.fecha_Cancelado).format('l')
//       : credito?.rechazado
//       ? 'Crédito Rechazado'
//       : 'Pendiente por Cancelar',
//   }));


export const listadoHistoricos = (creditos = []) =>
  creditos?.map((credito) => ({
    nombre: `${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.primerApellido}`,
    cedula: formateador(credito?.cuentaUsuario?.cedula),
    creditoId: credito?.id,
    valor_Aprobado: credito?.valor_Aprobado === null ? '-' : `$ ${formateador(credito?.valor_Aprobado)}`,
    fecha_Cancelado: credito.fecha_Cancelado
      ? moment(credito?.fecha_Cancelado).format('l')
      : credito?.rechazado
      ? 'Crédito Rechazado'
      : 'Pendiente por Cancelar',
  })) || [];
export const pagoCompromisoPorAsentar = (creditos) =>
  creditos.map((credito) =>
    credito.compromisoCreditos.filter(
      (compromiso) => compromiso.fecha_Pago_Compromiso !== null && compromiso.cumplio_Compomiso === null
    )
  );

/* eslint-disable no-unused-expressions */
import { Helmet } from 'react-helmet-async';

// @mui
import { Grid, Container, Typography } from '@mui/material';
// components

import { useLocation } from 'react-router-dom';
import { GraficoAnual } from '../components/graficoAnual/GraficoAnual';
import { PATH_NAME, TITLES } from '../constants';
// sections

import { TilePage } from '../tiles/pages/TilePage';
import { GraficoTotal } from '../components/graficoAnual/GraficoTotal';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {

  const location = useLocation();
  const isAdminHome = location?.pathname.includes(PATH_NAME.home_admin);

  return (
    <>
      <Helmet>
        <title> {isAdminHome ? TITLES.home_admin : TITLES.default} </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hola, bienvenido de nuevo!
        </Typography>

        <Grid container spacing={3}>
          <TilePage />

          <Grid item xs={12} md={6} lg={8}>
            <GraficoAnual />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <GraficoTotal />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

/* eslint-disable react/prop-types */
import MUIDataTable from 'mui-datatables';

// @mui
import { Card, Container, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import Scrollbar from '../scrollbar/Scrollbar';
import MuiDatatablesTextLabels from '../../helper/labelTablas';

export default function TableList({ titulo, columnas, data, loading }) {

  const options = {
    filterType: 'checkbox',
    textLabels: MuiDatatablesTextLabels,
  };


  return (
    <Container>
      {loading ? (
        <Grid>
          <Grid display="flex" justifyContent="center" alignItems="end">
            <Icon icon="line-md:loading-twotone-loop" color="#2a76d2" width="150" />
          </Grid>
        </Grid>
      ) : (
        <Card>
          <Scrollbar>
            <MUIDataTable title={titulo} data={data} columns={columnas} options={options} />
          </Scrollbar>
        </Card>
      )}
    </Container>
  );
}

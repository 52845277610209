// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   creditos: [],
//   loading: true,
//   informe: [],
//   historicoClienteEditable: null,
//   loadingHistoricoClienteEditable: true,
// };

// export const historicosSlice = createSlice({
//   name: 'historicos',
//   initialState,
//   reducers: {
//     cargarHistoricos: (state, action) => {
//       state.creditos = action.payload;
//       state.loading = false;
//     },
//     cargarInforme: (state, action) => {
//       state.informe = action.payload;
//     },
//     cargarHistoricoClienteEditable: (state, action) => {
//       state.historicoClienteEditable = action.payload;
//       state.loadingHistoricoClienteEditable = false;
//     },
//   },
// });

// export const { cargarHistoricos, cargarInforme, cargarHistoricoClienteEditable } = historicosSlice.actions;


import { createSlice } from '@reduxjs/toolkit';

const initialState = {
 creditos: [],
 loading: true,
 informe: [],
 historicoClienteEditable: null,
 loadingHistoricoClienteEditable: true,
 paginacion: {
   total: 0, 
   currentPage: 1,
   totalPages: 0
 }
};

export const historicosSlice = createSlice({
 name: 'historicos',
 initialState,
 reducers: {
   cargarHistoricos: (state, action) => {
     state.creditos = action.payload.creditos;
     state.paginacion = {
       total: action.payload.total,
       currentPage: action.payload.page,
       totalPages: action.payload.totalPages
     };
     state.loading = false;
   },
   cargarInforme: (state, action) => {
     state.informe = action.payload;
   },
   cargarHistoricoClienteEditable: (state, action) => {
     state.historicoClienteEditable = action.payload;
     state.loadingHistoricoClienteEditable = false;
   },
 },
});

export const { cargarHistoricos, cargarInforme, cargarHistoricoClienteEditable } = historicosSlice.actions;
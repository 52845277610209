/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardMedia, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import InformacionPersonal from './InformacionPersonal';
import ComoEmpleado from './ComoEmpleado';
import ComoIndependiente from './ComoIndependiente';
import ComoDesempleado from './ComoDesempleado';
import ComoPensionado from './ComoPensionado';
import ReferenciaPersonal from './ReferenciaPersonal';
import ReferenciaFamiliar from './ReferenciaFamiliar';
import CalificacionUsuario from './CalificacionUsuario';
import { actualizarCliente } from '../../store';
import { MAIN_PATH, PATH_NAME } from '../../constants';

const EditarDatosPersonales = () => {
  const navigate = useNavigate();
  const classes = {};
  const dispatch = useDispatch();
  const clienteInfo = useSelector((state) => state?.clientes?.clienteEditable);

  const [expanded, setExpanded] = React.useState(false);

  const Input = styled('input')({
    display: 'none',
  });

  const [informacionPersonal, setInformacionPersonal] = useState({
    primerNombre: clienteInfo?.primerNombre,
    segundoNombre: clienteInfo?.segundoNombre,
    primerApellido: clienteInfo?.primerApellido,
    segundoApellido: clienteInfo?.segundoApellido,
    fecha_de_expedicion_cedula: clienteInfo?.fecha_de_expedicion_cedula,
    cedula: clienteInfo?.cedula,
    direccion_residencia: clienteInfo?.direccion_residencia,
    celular: clienteInfo?.celular,
    ciudad_residencia: clienteInfo?.ciudad_residencia,
    estrato: clienteInfo?.estrato,
    tipo_vivienda: clienteInfo?.tipo_vivienda,
    nivel_de_estudios: clienteInfo?.nivel_de_estudios,
    genero: clienteInfo?.genero,
    email: clienteInfo?.usuario?.email,
    banco: clienteInfo?.banco,
    numero_cuenta: clienteInfo?.numero_cuenta,
    tipo_cuenta: clienteInfo?.tipo_cuenta,
    // tiene_tarjeta: clienteInfo.tiene_tarjeta,
    ingresos_Mensuales: clienteInfo?.ingresos_Mensuales,
    image: clienteInfo?.image,
    gastos_Mensuales: clienteInfo?.gastos_Mensuales,
    calificacion: clienteInfo?.calificacion,
  });

  const [empleado, setEmpleado] = useState({
    nombre_empresa: clienteInfo?.nombre_empresa,
    nit: clienteInfo?.nit,
    ciudad_trabajo: clienteInfo?.ciudad_trabajo,
    direccion_trabajo: clienteInfo?.direccion_trabajo,
    // telefono_trabajo: clienteInfo?.telefono_trabajo,
    tipo_contrato: clienteInfo?.tipo_contrato,
    cargo: clienteInfo?.cargo,
    eps: clienteInfo?.eps,
    // fecha_de_ingreso_empresa: clienteInfo?.fecha_de_ingreso_empresa,
  });

  const [independiente, setIndependiente] = useState({
    nombre_empresa_independiente: clienteInfo?.nombre_empresa_independiente,
    ciudad_empresa_independiente: clienteInfo?.ciudad_empresa_independiente,
    direccion_empresa_independiente: clienteInfo?.direccion_empresa_independiente,
    telefono_empresa_independiente: clienteInfo?.telefono_empresa_independiente,
    actividad_economica: clienteInfo?.actividad_economica,
  });
  const [desempleado, setDesempleado] = useState({
    tiempo_desempleado: clienteInfo?.tiempo_desempleado,
  });
  const [pensionado, setPensionado] = useState({
    fondo_pensional: clienteInfo?.fondo_pensional,
    causa_pension: clienteInfo?.causa_pension,
    fecha_resolucion_pension: clienteInfo?.fecha_resolucion_pension,
  });
  const [referenciaPersonal, setReferenciaPersonal] = useState({
    referencia_personal_nombres: clienteInfo?.referencia_personal_nombres,
    referencia_personal_apellidos: clienteInfo?.referencia_personal_apellidos,
    referencia_personal_ciudad: clienteInfo?.referencia_personal_ciudad,
    referencia_personal_telefono: clienteInfo?.referencia_personal_telefono,
  });
  // const [referenciaFamiliar, setReferenciaFamiliar] = useState({
  //   referencia_familiar_nombres: clienteInfo.referencia_familiar_nombres,
  //   referencia_familiar_apellidos: clienteInfo.referencia_familiar_apellidos,
  //   referencia_familiar_ciudad: clienteInfo.referencia_familiar_ciudad,
  //   referencia_familiar_telefono: clienteInfo.referencia_familiar_telefono,
  //   referencia_familiar_parentesco: clienteInfo.referencia_familiar_parentesco,
  // });

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDatosChange = (e) => {
    setInformacionPersonal({
      ...informacionPersonal,
      image: e.target.files[0],
    });
  };
  const handlePuntuacion = (e) => {
    setInformacionPersonal({
      ...informacionPersonal,
      calificacion: e.target.value,
    });
  };

  function handleClick() {
    navigate(-1);
  }

  const actualizar = (e) => {
    e.preventDefault();
    Swal.fire({
      title: '¿Estas seguro de actualizar esta información?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Actualizar!',
      cancelButtonText: 'No, cancelar',
    }).then((result) => {
      if (result.value) {
        dispatch(
          actualizarCliente(
            {
              ...clienteInfo,
              ...desempleado,
              ...empleado,
              ...independiente,
              ...informacionPersonal,
              ...pensionado,
              // ...referenciaFamiliar,
              ...referenciaPersonal,
            },
            clienteInfo.id
          )
        );
        navigate(`/admin/${MAIN_PATH}/${PATH_NAME.home_admin}`);
      }
    });
  };
  return (
    <Card>
      <Box sx={{ margin: 3 }}>
        <form className={classes.root} onSubmit={actualizar}>
          <h1>{`${clienteInfo?.primerNombre} ${clienteInfo?.primerApellido}`}</h1>
          <div className={classes.rootDatos}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography className={classes.heading}>Informacion Personal</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <InformacionPersonal
                  clienteInfo={informacionPersonal}
                  setInformacionPersonal={setInformacionPersonal}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                <Typography className={classes.heading}>Como Empleado</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ComoEmpleado clienteInfo={empleado} setEmpleado={setEmpleado} />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                <Typography className={classes.heading}>Como Independiente</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ComoIndependiente clienteInfo={independiente} setIndependiente={setIndependiente} />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                <Typography className={classes.heading}>Como Desempleado</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ComoDesempleado clienteInfo={desempleado} setDesempleado={setDesempleado} />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                <Typography className={classes.heading}>Como Pensionado</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ComoPensionado clienteInfo={pensionado} setPensionado={setPensionado} />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                <Typography className={classes.heading}>Referencia Personal</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ReferenciaPersonal clienteInfo={referenciaPersonal} setReferenciaPersonal={setReferenciaPersonal} />
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'panel8'}
              onChange={handleChange('panel8')}
              disabled // esto se arreglara cuando se pueda guardar el pdf de data credito en la DB
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="image">
                <Typography className={classes.heading}>Data Credito</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={handleDatosChange}
                    size="lg"
                  />
                  <Button variant="contained" component="span">
                    {clienteInfo?.image ? 'cambiar' : 'Agregar'}
                  </Button>
                </label>
                <Card sx={{ maxWidth: '100%', marginTop: 5 }}>
                  <CardMedia
                    component="img"
                    height="640"
                    image="/static/images/cards/contemplative-reptile.jpg"
                    alt="Pendiente"
                  />
                </Card>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="image">
                <Typography className={classes.heading}>Calificación del Usuario</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CalificacionUsuario puntuacion={informacionPersonal} handleCalificacion={handlePuntuacion} />
              </AccordionDetails>
            </Accordion>
          </div>
          <br />

          <div className={classes.rootButton}>
            <Button
              variant="contained"
              color="warning"
              sx={{ marginRight: 3 }}
              onClick={handleClick}
              startIcon={<ReplyIcon />}
            >
              Atras
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Guardar
            </Button>
          </div>
        </form>
      </Box>
    </Card>
  );
};

export default EditarDatosPersonales;
